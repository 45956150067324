import React from "react";
import { Typography, Layout, Divider } from "antd";

const { Title, Paragraph, Text } = Typography;
const { Content } = Layout;

const PrivacyPolicy = () => {
  return (
    <Layout style={{ minHeight: "100vh", padding: "20px" }}>
      <Content style={{ backgroundColor: "#fff", padding: "20px" }}>
        <Typography>
          <Title level={2} style={{ textAlign: "center" }}>
            Privacy Policy
          </Title>
          <Paragraph>
            <Text strong>Effective Date:</Text>  1st December 2024
          </Paragraph>

          <Divider />
          <Title level={3}>1. Introduction</Title>
          <Paragraph>
            QikMarket (“we,” “our,” or “us”) is committed to protecting your
            privacy. This Privacy Policy explains how we collect, use, and
            safeguard your information when you use our platform.
          </Paragraph>

          <Divider />
          <Title level={3}>2. Information We Collect</Title>
          <Paragraph>
            <Text strong>2.1 Information You Provide:</Text> We collect
            information you provide when creating an account, such as your:
            <ul>
              <li>Name</li>
              <li>Email address</li>
              <li>Phone number</li>
              <li>Delivery address</li>
              <li>Payment details</li>
            </ul>
          </Paragraph>
          <Paragraph>
            <Text strong>2.2 Automatically Collected Information:</Text> When
            you use QikMarket, we automatically collect:
            <ul>
              <li>IP address</li>
              <li>Device information</li>
              <li>Browser type</li>
              <li>Usage data (e.g., pages viewed, time spent on the platform)</li>
            </ul>
          </Paragraph>

          <Divider />
          <Title level={3}>3. How We Use Your Information</Title>
          <Paragraph>
            <Text strong>3.1 To Provide Services:</Text> We use your information
            to process transactions, manage your account, and facilitate
            deliveries.
          </Paragraph>
          <Paragraph>
            <Text strong>3.2 To Improve the Platform:</Text> Usage data helps us
            optimize the platform’s functionality and user experience.
          </Paragraph>
          <Paragraph>
            <Text strong>3.3 Marketing:</Text> With your consent, we may send
            promotional communications about offers, discounts, or services
            available on QikMarket.
          </Paragraph>
          <Paragraph>
            <Text strong>3.4 Legal Compliance:</Text> We may use your
            information to comply with applicable laws and regulations.
          </Paragraph>

          <Divider />
          <Title level={3}>4. Sharing Your Information</Title>
          <Paragraph>
            <Text strong>4.1 With Delivery Partners:</Text> Necessary details
            such as delivery address and contact information may be shared with
            drivers to complete orders.
          </Paragraph>
          <Paragraph>
            <Text strong>4.2 With Third-Party Service Providers:</Text> We share
            information with payment processors, analytics providers, and other
            service providers as needed to operate the platform.
          </Paragraph>
          <Paragraph>
            <Text strong>4.3 Legal Obligations:</Text> We may disclose
            information if required by law or to protect our legal rights.
          </Paragraph>

          <Divider />
          <Title level={3}>5. Data Security</Title>
          <Paragraph>
            We implement industry-standard security measures to protect your
            information. However, no system is completely secure, and we cannot
            guarantee the absolute security of your data.
          </Paragraph>

          <Divider />
          <Title level={3}>6. Cookies</Title>
          <Paragraph>
            QikMarket uses cookies to enhance your browsing experience and
            collect usage data. You can control cookie preferences through your
            browser settings.
          </Paragraph>

          <Divider />
          <Title level={3}>7. Your Rights</Title>
          <Paragraph>
            You have the right to:
            <ul>
              <li>Access the information we hold about you.</li>
              <li>Request corrections to inaccurate information.</li>
              <li>Request deletion of your personal data (subject to legal
              requirements).</li>
              <li>Opt-out of marketing communications.</li>
            </ul>
          </Paragraph>

          <Divider />
          <Title level={3}>8. Retention of Information</Title>
          <Paragraph>
            We retain your personal information only for as long as necessary to
            fulfill the purposes outlined in this Privacy Policy or comply with
            legal obligations.
          </Paragraph>

          <Divider />
          <Title level={3}>9. Updates to This Policy</Title>
          <Paragraph>
            QikMarket reserves the right to update this Privacy Policy. Any
            changes will be communicated via email or notifications on the
            platform.
          </Paragraph>
        </Typography>
      </Content>
    </Layout>
  );
};

export default PrivacyPolicy;