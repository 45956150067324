import React from "react";
import { Typography, Layout, Divider } from "antd";

const { Title, Paragraph, Text } = Typography;
const { Content } = Layout;

const TermsAndConditions = () => {
  return (
    <Layout style={{ minHeight: "100vh", padding: "20px" }}>
      <Content style={{ backgroundColor: "#fff", padding: "20px" }}>
        <Typography>
          <Title level={2} style={{ textAlign: "center" }}>
            Terms and Conditions
          </Title>
          <Paragraph>
            <Text strong>Effective Date:</Text> 1st December 2024
          </Paragraph>

          <Divider />
          <Title level={3}>1. Definitions</Title>
          <Paragraph>
            <Text strong>Platform:</Text> Refers to the QikMarket online
            platform where users can buy and sell products.
          </Paragraph>
          <Paragraph>
            <Text strong>Supplier:</Text> Any entity or individual listing and
            selling goods on QikMarket.
          </Paragraph>
          <Paragraph>
            <Text strong>Buyer:</Text> Any individual or entity purchasing goods
            listed on QikMarket.
          </Paragraph>
          <Paragraph>
            <Text strong>Delivery Partner:</Text> A driver or logistics provider
            affiliated with QikTruck who facilitates the delivery of goods.
          </Paragraph>
          <Paragraph>
            <Text strong>Goods:</Text> Products listed on QikMarket for sale.
          </Paragraph>
          <Paragraph>
            <Text strong>Promocode:</Text> A unique identifier linking buyers
            and sellers for discount offers or specific relationships.
          </Paragraph>
          <Paragraph>
            <Text strong>Fees:</Text> Refers to any charges payable by Suppliers
            or Buyers for using QikMarket services.
          </Paragraph>

          <Divider />
          <Title level={3}>2. General Use of the Platform</Title>
          <Paragraph>
            <Text strong>2.1 Eligibility:</Text> You must be 18 years or older
            to register and use QikMarket.
          </Paragraph>
          <Paragraph>
            <Text strong>2.2 Account Creation:</Text> Users must create an
            account to access certain platform features. You are responsible for
            safeguarding your account credentials.
          </Paragraph>
          <Paragraph>
            <Text strong>2.3 Compliance:</Text> Users must comply with all
            applicable laws and regulations when using QikMarket.
          </Paragraph>
          <Paragraph>
            <Text strong>2.4 Prohibited Activities:</Text>
            <ul>
              <li>Listing or purchasing illegal or restricted items.</li>
              <li>Misrepresenting product information.</li>
              <li>Violating intellectual property rights of others.</li>
            </ul>
          </Paragraph>

          <Divider />
          <Title level={3}>3. Supplier Obligations</Title>
          <Paragraph>
            <Text strong>3.1 Product Listings:</Text> Suppliers must provide
            accurate and complete details, including product names,
            descriptions, prices, stock levels, dimensions, and delivery
            instructions.
          </Paragraph>
          <Paragraph>
            <Text strong>3.2 Compliance:</Text> Suppliers are responsible for
            ensuring that the goods comply with applicable laws and do not
            infringe on third-party rights.
          </Paragraph>
          <Paragraph>
            <Text strong>3.3 Delivery Information:</Text> Suppliers must ensure
            that accurate product location details (latitude and longitude) and
            delivery instructions are provided.
          </Paragraph>
          <Paragraph>
            <Text strong>3.4 Returns and Refunds:</Text> Suppliers must set and
            adhere to their return and refund policies, provided they comply
            with consumer protection laws.
          </Paragraph>
          <Paragraph>
            <Text strong>3.5 Service Fees:</Text> Suppliers agree to pay
            QikTruck a 20% service fee on each sale.
          </Paragraph>

          <Divider />
          <Title level={3}>4. Buyer Obligations</Title>
          <Paragraph>
            <Text strong>4.1 Order Accuracy:</Text> Buyers must ensure accurate
            delivery addresses and product selections.
          </Paragraph>
          <Paragraph>
            <Text strong>4.2 Payment:</Text> Buyers must make payments for
            orders via the platform’s supported payment methods.
          </Paragraph>
          <Paragraph>
            <Text strong>4.3 Delivery Inspection:</Text> Buyers must inspect
            goods upon delivery and report issues within 48 hours.
          </Paragraph>
          <Paragraph>
            <Text strong>4.4 Prohibited Actions:</Text> Buyers must not abuse
            return policies or make fraudulent claims.
          </Paragraph>

          <Divider />
          <Title level={3}>5. Delivery Terms</Title>
          <Paragraph>
            <Text strong>5.1 Delivery Services:</Text> QikTruck coordinates
            deliveries via its network of drivers. Delivery costs are calculated
            based on distance and vehicle type.
          </Paragraph>
          <Paragraph>
            <Text strong>5.2 Same-Day Delivery:</Text> Same-day delivery is
            subject to product availability, supplier readiness, and distance
            constraints.
          </Paragraph>
          <Paragraph>
            <Text strong>5.3 Delivery Fees:</Text> Delivery fees are separate
            from product prices and are disclosed during checkout.
          </Paragraph>
          <Paragraph>
            <Text strong>5.4 Liability for Delays:</Text> QikTruck is not liable
            for delays caused by factors outside its control, such as traffic,
            weather, or supplier readiness.
          </Paragraph>
        </Typography>
      </Content>
    </Layout>
  );
};

export default TermsAndConditions;
