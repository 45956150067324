import axios from "axios";
import React, { useEffect, useState } from "react";
import { Form, Input, Button, notification } from "antd";
import { useLocation } from "react-router-dom";

const Unsubscribe = () => {
  const [email, setEmail] = useState("");
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const emailParam = params.get("email");
    if (emailParam) {
      setEmail(emailParam);
    } else {
      notification.error({ message: "No email provided in URL." });
    }
  }, [location.search]);

  const handleUnsubscribe = async () => {
    // /unsubscribe?email=manu@qiktruck.co.za&mailer=QikMarket&source_=QikMarket&category=General
    const params = new URLSearchParams(location.search);

    try {
      await axios.post(`https://zaprod.qiktruck.co.za/unsubscribe`, {
        email: params.get("email"),
        mailer: params.get("mailer"),
        source_: params.get("source_"),
        category: params.get("category"),
      });
      notification.success({ message: `Unsubscribed ${email} successfully.` });
    } catch (error) {
      notification.error({ message: "Error unsubscribing." });
    }
  };

  return (
    <Form
      layout="vertical"
      style={{ maxWidth: 400, margin: "0 auto", marginTop: "50px" }}
    >
      <Form.Item label="Email">
        <Input value={email || "no email provided"} disabled readOnly />
      </Form.Item>
      <Form.Item>
        <Button type="primary" onClick={handleUnsubscribe} disabled={!email}>
          Unsubscribe
        </Button>
      </Form.Item>
    </Form>
  );
};

export default Unsubscribe;
